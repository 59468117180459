import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "./logo.png";
import "./Sidebar.css";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
// import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear token from cookies
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    // Redirect to home page
    navigate("/");
  };

  return (
    <div className="sidebarContainer">
      <div className="bar">
        <div className="sidebarLogo">
          <img src={logo} alt="" />
        </div>
        <div className="menuBox">
          {/* -----UPPER MENU----- */}
          <div className="menuUpper">
            {/* -------DashBoard------- */}
            <Link className="menuItem" to="/dashboard">
              <div className="icon">
                <DashboardCustomizeOutlinedIcon style={{ fontSize: "2em" }} />
              </div>
              <div className="heading">Dashboard</div>
            </Link>
            {/* ----------Device Details--------- */}
            <Link className="menuItem" to="/devices">
              <div className="icon">
                <DeviceHubOutlinedIcon style={{ fontSize: "2em" }} />
              </div>
              <div className="heading">Devices</div>
            </Link>
            <Link className="menuItem" to="/mapview">
              <div className="icon">
                <AddLocationAltIcon style={{ fontSize: "2em" }} />
              </div>
              <div className="heading">Map</div>
            </Link>
          </div>
          {/* -----LOWER MENU----- */}
          <div className="menuLower">
            {/* -------VIEW PROFILE------- */}

            {/* ----------Contact Support--------- */}
            <Link className="menuItem" to="/support">
              <div className="icon">
                <MiscellaneousServicesOutlinedIcon
                  style={{ fontSize: "2em" }}
                />
              </div>
              <div className="heading">Support</div>
            </Link>

            {/* -----------User Details---------- */}
            <div className="menuItem" onClick={handleLogout}>
              <div className="icon">
                <LogoutOutlinedIcon style={{ fontSize: "2em" }} />
              </div>
              <div className="heading">Logout</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
