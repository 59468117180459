import React from "react";
import "./DeviceComponent.css";
import solarIcon from "./Icons/solar_panels.png";
// import solarIcon from "../../Pages/Dashboard/Icons/solar_panels.png";
import clock from "./Icons/clock.png";
import voltage from "./Icons/voltage.png";
import current from "./Icons/dc-current.png";
// import waterIcon from "../../Pages/Dashboard/Icons/water_drop.png";
import fuel from "./Icons/fuels.png";
import plant from "./Icons/plant.png";
// import installIcon from "../../Pages/Dashboard/Icons/dev_installed.png";
const MiniCard = ({ inputType, minicardData }) => {
  let miniCardData;
  switch (inputType) {
    case "sessionTime":
      miniCardData = {
        miniCardTitle: "Session Length",
        miniCardIcon: clock,
        miniCardValue: minicardData || 0,
        miniCardUnit: "Hrs.",
      };
      break;
    case "fuelSavings":
      miniCardData = {
        miniCardTitle: "Fuel Saving",
        miniCardIcon: fuel,
        miniCardValue: minicardData || 0,
        miniCardUnit: "litres",
      };
      break;
    case "carbonSaving":
      miniCardData = {
        miniCardTitle: "Carbon Saving",
        miniCardIcon: plant,
        miniCardValue: minicardData || 0,
        miniCardUnit: "Trees",
      };
      break;
    case "voltage":
      miniCardData = {
        miniCardTitle: "Voltage",
        miniCardIcon: voltage,
        miniCardValue: minicardData || 0,
        miniCardUnit: "Volt",
      };
      break;
    case "current":
      miniCardData = {
        miniCardTitle: "Current",
        miniCardIcon: current,
        miniCardValue: minicardData || 0,
        miniCardUnit: "Amp",
      };
      break;
    case "frequency":
      miniCardData = {
        miniCardTitle: "Frequency",
        miniCardIcon: solarIcon,
        miniCardValue: minicardData || 0,
        miniCardUnit: "Hz",
      };
      break;
    default:
      miniCardData = {
        miniCardTitle: "Unknown",
        miniCardIcon: solarIcon,
        miniCardValue: 0,
        miniCardUnit: "",
      };
      break;
  }
  return (
    <div className="miniCardContainer">
      <div className="miniCardTop">
        <img
          className="miniCardIcon"
          src={miniCardData.miniCardIcon}
          alt="icon"
        />
        <span className="miniCardTitle">{miniCardData.miniCardTitle}</span>
      </div>
      <div className="miniCardBottom">
        <span className="miniCardNumber">{miniCardData.miniCardValue}</span>
        <span className="miniCardUnit">{miniCardData.miniCardUnit}</span>
      </div>
    </div>
  );
};

export default MiniCard;
