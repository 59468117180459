import React, { useState, useEffect } from "react";
import Copyright from "../../Global/Copyright";
import "./Support.css";
import Data from "./../../../ClientSideData.json";
import Sidebar from "../../Global/Sidebar";
import Cookies from "js-cookie";
const Support = () => {
  const [userName, setUserName] = useState([]);

  useEffect(() => {
    fetch("/api/topBar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserName(data.userName);
        // setLastUpdated(data.lastUpdated);
      })
      .catch((error) => {
        console.error("Error fetching marker data:", error);
      });
  }, []);
  return (
    <div className="supportPage">
      <div className="sidebarSupportContainer">
        <Sidebar />
      </div>
      <div className="supportMainContent">
        <div className="bodyContent">
          <div className="addressClient">
            <span>Hello,</span>
            {Data.map((user) => {
              return <div className="nameClient">{userName}</div>;
            })}
          </div>
          <div className="comingSoon">
            <span>Our Online Support will be up and running soon.</span>
          </div>
          <div className="tollfree">
            <div> Meanwhile contact</div> <span>1800 313 8292</span>{" "}
            <div> for support.</div>
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  );
};

export default Support;
