import React from "react";
import "./DeviceComponent.css";
import solarIcon from "./Icons/solar_panels.png";
import power from "./Icons/power.png";
import clock from "./Icons/clock.png";
import waterPump from "./Icons/waterPump.png";
import drive from "./Icons/drive.png";
// import solarIcon from "../../Pages/Dashboard/Icons/solar_panels.png";
// import clockIcon from "../../Pages/Dashboard/Icons/clock.png";
// import waterIcon from "../../Pages/Dashboard/Icons/water_drop.png";
// import fuelIcon from "../../Pages/Dashboard/Icons/fuels.png";
// import plantIcon from "../../Pages/Dashboard/Icons/plant.png";
// import installIcon from "../../Pages/Dashboard/Icons/dev_installed.png";
const Card = ({ inputType, cardData }) => {
  let CardData;
  switch (inputType) {
    case "totalPanels":
      CardData = {
        cardTitle: "Solar Panels",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Nos.",
      };
      break;
    case "installedCapacity":
      CardData = {
        cardTitle: "Total Power",
        cardIcon: power,
        cardValue: cardData || 0,
        cardUnit: "Wp",
      };
      break;
    case "panels":
      CardData = {
        cardTitle: "Solar Panels",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Nos.",
      };
      break;
    case "sessionTime":
      CardData = {
        cardTitle: "Session Length",
        cardIcon: clock,
        cardValue: cardData || 0,
        cardUnit: "Hrs.",
      };
      break;
    case "waterFlow":
      CardData = {
        cardTitle: "Pump Capacity",
        cardIcon: waterPump,
        cardValue: cardData || 0,
        cardUnit: "HP",
      };
      break;
    case "fuelSavings":
      CardData = {
        cardTitle: "Fuel Saving",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "litres",
      };
      break;
    case "carbonSaving":
      CardData = {
        cardTitle: "Carbon Saving",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Trees",
      };
      break;
    case "voltage":
      CardData = {
        cardTitle: "Voltage",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Volt",
      };
      break;
    case "current":
      CardData = {
        cardTitle: "Current",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Amp",
      };
      break;
    case "frequency":
      CardData = {
        cardTitle: "Frequency",
        cardIcon: solarIcon,
        cardValue: cardData || 0,
        cardUnit: "Hz",
      };
      break;
    case "vfd":
      CardData = {
        cardTitle: "Var. Freq. Drive",
        cardIcon: drive,
        cardValue: cardData || 0,
        cardUnit: " ",
      };
      break;
    default:
      CardData = {
        cardTitle: "Unknown",
        cardIcon: solarIcon,
        cardValue: 0,
        cardUnit: "",
      };
      break;
  }
  return (
    <div className="cardContainer">
      <div className="cardTop">
        <img className="cardIcon" src={CardData.cardIcon} alt="icon" />
        <span className="cardTitle">{CardData.cardTitle}</span>
      </div>
      <div className="cardBottom">
        <span className="cardNumber">{CardData.cardValue}</span>
        <span className="cardUnit">{CardData.cardUnit}</span>
      </div>
    </div>
  );
};

export default Card;
