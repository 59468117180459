import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import Copyright from "../../Global/Copyright";
import logo from "./logo.png";
import bgVideo from "./bgVideo1.mp4";
import errorIcon from "./error.png";

function HomePage() {
  const navigate = useNavigate();
  const [loginId, setLoginId] = useState("");
  const [password, setPassword] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  function handleLogin() {
    // Make API call to authenticate with the backend
    fetch("/api/auth", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: loginId,
        pass: password,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          setShowPopup(true); // Show popup if authentication fails
          setTimeout(() => {
            setShowPopup(false); // Hide the popup after 1 second
          }, 10000);
        } else if (response.ok) {
          return response.json(); // Parse the response as JSON
        }
      })
      .then((data) => {
        if (data) {
          console.log(data);
          document.cookie = "token=" + data;
          console.log(document.cookie);
          navigate("/Dashboard");
        }
      })
      .catch((error) => {
        // Handle error
        console.error(error);
      });
  }

  return (
    <div className="home-page">
      <div className="background-video">
        <video src={bgVideo} autoPlay loop muted />
      </div>
      <div className="overlay">
        <div className="content">
          <h1 className="welcome-text">Welcome to</h1>
          <img src={logo} alt="Logo" className="logo" />

          <div className="login-box">
            <input
              type="text"
              placeholder="Login ID"
              className="input-field"
              value={loginId}
              onChange={(e) => setLoginId(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button className="login-button" onClick={handleLogin}>
              Login
            </button>
          </div>
          {showPopup && (
            <div className="popup">
              <img src={errorIcon} alt="error" className="popup-icon" />
              Invalid User ID or Password
            </div>
          )}
        </div>

        <div className="footer">
          <Copyright />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
