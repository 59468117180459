import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
// import "./Chart.css";
import Cookies from "js-cookie";
function LinePlot({ series, categories }) {
  return (
    <div>
      <Chart
        type="area"
        width="220%"
        height="110%"
        series={series}
        options={{
          title: {
            text: " Energy Savings (Monthly) ",
            margin: 0,
            offsetX: 0,
            offsetY: 10,
            style: {
              fontSize: "16px",
              fontWeight: "bold",
              fontFamily: "Poppins, Arial",
              color: "#263238",
            },
            fill: {
              type: "gradient",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
                stops: [0, 90, 100],
              },
            },
          },
          tooltip: {
            enabled: true,
            enabledOnSeries: undefined,
            shared: true,
            followCursor: true,
            intersect: false,
            inverseOrder: false,
            custom: undefined,
            fillSeriesColor: true,
            theme: true,
            style: {
              fontSize: "12px",
              fontFamily: "Poppins",
            },
            onDatasetHover: {
              highlightDataSeries: true,
            },
            x: {
              show: false,
              format: "dd MMM",
              formatter: undefined,
            },
            y: {
              formatter: undefined,
              title: {
                formatter: (seriesName) => seriesName,
              },
            },
            z: {
              formatter: undefined,
              title: "Size: ",
            },
            marker: {
              show: true,
            },
            items: {
              display: "flex",
            },
            fixed: {
              enabled: false,
              position: "topLeft",
              offsetX: 0,
              offsetY: 0,
            },
          },
          chart: {
            stacked: false,
          },
          stroke: {
            curve: "smooth",
            width: 2,
          },
          xaxis: {
            categories: categories,
          },
          yaxis: {
            labels: {
              show: true,
              align: "right",
              minWidth: 0,
              maxWidth: 500,
              style: {
                fontSize: "10px",
                fontFamily: "Poppins, Arial, sans-serif",
                fontWeight: 400,
                cssClass: "apexcharts-yaxis-label",
              },
            },
          },

          legend: {
            show: true,
            position: "bottom",
            fontSize: "10px",
            fontFamily: "Poppins, Arial",
            fontWeight: 400,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            show: true,
            borderColor: "#90A4AE",
            strokeDashArray: 1,
            position: "back",
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          responsive: [
            {
              breakpoint: 1001, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "145%", // adjust the width for smaller screens
                  height: "95%",
                },
                title: {
                  style: {
                    fontSize: "14px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "10px",
                      fontWeight: 600,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "10px",
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "10px",
                      // fontWeight: 600,
                    },
                  },
                },
                legend: {
                  fontSize: "10px",
                },
              },
            },
            {
              breakpoint: 1281, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "190%", // adjust the width for smaller screens
                  height: "150%",
                },
                title: {
                  style: {
                    fontSize: "14px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "10px",
                      fontWeight: 600,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "10px",
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "11px",
                      // fontWeight: 600,
                    },
                  },
                },
                legend: {
                  fontSize: "11px",
                },
              },
            },
            {
              breakpoint: 1370, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "205%", // adjust the width for smaller screens
                  height: "135%",
                },
                title: {
                  style: {
                    fontSize: "13px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "12px",
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  },
                },
                legend: {
                  fontSize: "11px",
                },
              },
            },
            {
              breakpoint: 1501, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "225%", // adjust the width for smaller screens
                  height: "95%",
                },
                title: {
                  style: {
                    fontSize: "18px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 600,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "13px",
                      // fontWeight: 600,
                    },
                  },
                },
                legend: {
                  fontSize: "14px",
                },
              },
            },
            {
              breakpoint: 1701, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "350", // adjust the width for smaller screens
                  height: "155",
                },
                title: {
                  style: {
                    fontSize: "16px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "15px",
                      fontWeight: 600,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "15px",
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "15px",
                      // fontWeight: 600,
                    },
                  },
                },
                legend: {
                  fontSize: "15px",
                },
              },
            },
            {
              breakpoint: 1950, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "830", // adjust the width for smaller screens
                  height: "350",
                },
                title: {
                  style: {
                    fontSize: "20px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "15px",
                      fontWeight: 600,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "16px",
                      fontWeight: 600,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "14px",
                      // fontWeight: 600,
                    },
                  },
                },
                legend: {
                  fontSize: "16px",
                },
              },
            },
          ],
        }}
      />
    </div>
  );
}

export default LinePlot;
