import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "./Components/Pages/Home/Home";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import Devices from "./Components/Pages/Devices/Devices";
import Contact from "./Components/Pages/Contact/Contact";
import Support from "./Components/Pages/Support/Support";
import "./Components/Global/Sidebar.css";
import mapboxgl from "mapbox-gl";
import MapView from "./Components/Pages/MapView/MapView";
import Device from "./Components/Pages/Device/Device";; 

mapboxgl.accessToken =
  "pk.eyJ1IjoiY3Jtcy10ZXN0IiwiYSI6ImNsazZqcnljaDAxdTQzZXFlcXZneHRwYWEifQ.GIfw5haMe7BIRV1KrgTq4A";
function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/device/:devicename" element={<Device />} />
          <Route path="/mapview" element={<MapView />} />
          {/* <Route path="/Profile" element={<Profile />} /> */}
          <Route path="/support" element={<Support />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
