import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import "./radialBar.css"

const RadialBar = ({ val, col }) => {
  const [radialBarState, setRadialBarState] = useState({
    series: [val],

    options: {
      chart: {
        type: "radialBar",
        align: "right",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,

          hollow: {
            margin: 0,
            size: "60%",

            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              blur: 2,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -2,
              left: 0,
              blur: 2,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: 0,
              show: false,
              color: "#888",
              fontSize: "100%",
            },
            value: {
              offsetY: 5,
              color: "#111",
              fontWeight: "bold",
              fontFamily: "Poppins, Arial",
              color: "#263238",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.2,
          gradientToColors: [col], //#ABE5A1, 
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
    },
  });
  useEffect(() => {
    setRadialBarState((prevState) => ({
      ...prevState,
      series: [val],
    }));
  }, [val]);
 
  console.log(radialBarState.series);
  return (
    <div className="radialBarChart">
      <ReactApexChart
        options={radialBarState.options}
        series={radialBarState.series}
        type="radialBar"
        height="145%"
      />
    </div>
  );
};

export default RadialBar;
