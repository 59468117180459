import React from "react";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { fontSize } from "@mui/system";
import "./Copyright.css";
const Copyright = () => {
  return (
    <div className="copyright">
      <span>
        Copyright <CopyrightIcon style={{ fontSize: "10px" }} />{" "}
        <span> Greenovera India Pvt. Ltd. </span> 2023
      </span>
    </div>
  );
};

export default Copyright;
