import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import "./Dashboard.css";
import Topbar from "../../Global/Topbar";
import Copyright from "../../Global/Copyright";
import Sidebar from "../../Global/Sidebar";
import LargeCard from "../../Global/Widgets/largeCard";
import TotalDevices from "../../Global/Widgets/specialCardOne";
import SmallCard from "../../Global/Widgets/smallCard";
import LineChart from "../../Global/Widgets/lineChart";
import BarChart from "../../Global/Widgets/barChart";
import Cookies from "js-cookie";
import { ThreeCircles, Triangle } from "react-loader-spinner";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([0]);
  const navigate = useNavigate(); // Get the navigate function
  const [lineChartSeries, setlineChartSeries] = useState([]);
  const [lineChartCategories, setlineChartCategories] = useState([]);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true);
  const [isLoadingLineChart, setIsLoadingLineChart] = useState(true);
  const [isLoadingBarChart, setIsLoadingBarChart] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token"); // Get token from cookies
        if (!token) {
          navigate("/"); // Redirect to home if no token
          return;
        }

        const fetchDashboardData = async () => {
          const response = await fetch("/api/dashboard", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            credentials: "include",
          });

          if (!response.ok) {
            if (response.status === 401) {
              // Unauthorized - Invalid token
              Cookies.remove("token");
              navigate("/");
            } else {
              // Handle other error cases
              throw new Error(`Request failed with status ${response.status}`);
            }
          }

          return response.json();
        };

        const poll = async () => {
          try {
            const dashData = await fetchDashboardData();
            setDashboardData(dashData);
            setIsLoadingDashboard(false);
            // Poll again after a delay (e.g., every 45 seconds)
            setTimeout(poll, 45000);
          } catch (error) {
            console.error("Error fetching dashboard data:", error);
            setTimeout(poll, 45000);
          }
        };

        poll(); // Start the long polling process
      } catch (error) {
        console.error("Error during initial fetch:", error);
        setIsLoadingDashboard(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch("/api/lineChart", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${Cookies.get("token")}`,
        },
        credentials: "include",
      });
      const data = await response.json();

      const sessionTimeData = data.historicalSessionTime.map((item) =>
        parseInt(item.total_session_time)
      );

      const fuelSavingsData = data.historicalFuelSavings.map((item) =>
        parseInt(item.total_fuel_savings)
      );

      const carbonSavingsData = data.historicalCarbonSavings.map((item) =>
        parseInt(item.total_carbon_savings)
      );

      setlineChartSeries([
        // {
        //   name: "Utilized Power (kW.)",
        //   data: installedCapacityData,
        // },
        {
          name: "Session Time (hrs.)",
          data: sessionTimeData,
        },
        {
          name: "Fuel Savings(ltrs.)",
          data: fuelSavingsData,
        },
        {
          name: "Carbon Savings",
          data: carbonSavingsData,
        },
      ]);

      const dateColumns = data.historicalCarbonSavings.map((item) => {
        const month = String(item.month).padStart(2, "0");
        return `${month}/${item.year}`;
      });
      setlineChartCategories(dateColumns);
      setIsLoadingLineChart(false);
    };

    fetchData().catch((error) => {
      console.error("Error fetching line chart data:", error);
      // setIsLoadingLineChart(false);
    });
  }, []);
  const [barChartSeries, setbarChartSeries] = useState([]);
  const [barChartCategories, setbarChartCategories] = useState([]);

  useEffect(() => {
    fetch("/api/barChart", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is in the format described above
        // Extract data and set the series state
        const activeDevicesData = data.barChartData.map((item) =>
          parseInt(item.active_devices)
        );
        const inactiveDevicesData = data.barChartData.map((item) =>
          parseInt(item.inactive_devices)
        );
        const faultDevicesData = data.barChartData.map((item) =>
          parseInt(item.fault_devices)
        );
        const disconnectedDevicesData = data.barChartData.map((item) =>
          parseInt(item.not_connected_devices)
        );

        setbarChartSeries([
          {
            name: "Pump ON",
            data: activeDevicesData,
          },
          {
            name: "Pump OFF",
            data: inactiveDevicesData,
          },
          {
            name: "Fault",
            data: faultDevicesData,
          },
          {
            name: "Disconnected",
            data: disconnectedDevicesData,
          },
        ]);
        // Extract date_column values and set as categories
        const dateColumns = data.barChartData.map((item) => {
          const date = new Date(item.sys_date);
          console.log(date);
          const day = String(date.getDate()).padStart(2, "0");
          const month = String(date.getMonth() + 1).padStart(2, "0");
          return `${day}/${month}`;
        });
        setbarChartCategories(dateColumns);
        setIsLoadingBarChart(false);
        // console.log(barChartSeries);
      })

      .catch((error) => {
        console.error("Error fetching bar chart data:", error);
        // setIsLoadingBarChart(false);
      });
  }, []);

  const calculatePercentage = (value, total) => {
    const numericValue = parseInt(value, 10);
    const numericTotal = parseInt(total, 10);

    if (isNaN(numericValue) || isNaN(numericTotal) || numericTotal === 0) {
      return 0;
    }

    return Math.round((numericValue / numericTotal) * 100);
  };
  console.log(isLoadingDashboard);
  console.log(isLoadingLineChart);
  console.log(isLoadingBarChart);

  return (
    <div className="dashMainContainer">
      <div className="sidebarContainer">
        <Sidebar />
      </div>
      <div className="dashSubContainer">
        <Topbar />
        <div className="mainContent">
          {isLoadingDashboard || isLoadingLineChart || isLoadingBarChart ? (
            <>
              <div className="loader">
                <Triangle
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                <span className="loadText">
                  Please wait, I am loading your data...
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="siteInfoWidgets">
                <div className="siteInfoSection-1">
                  <SmallCard
                    inputType={"totalDevice"}
                    smallCardData={dashboardData.totalDeviceCount}
                  />
                  <SmallCard
                    inputType={"installedCapacity"}
                    smallCardData={dashboardData.installedCapacity}
                  />
                  <SmallCard
                    inputType={"sessionTime"}
                    smallCardData={dashboardData.sessionTime}
                  />
                </div>
                <div className="siteInfoSection-2">
                  <SmallCard
                    inputType={"waterFlow"}
                    smallCardData={dashboardData.pumpingCapacity}
                  />
                  <SmallCard
                    inputType={"fuelSavings"}
                    smallCardData={dashboardData.fuelSaved}
                  />
                  <SmallCard
                    inputType={"carbonSaving"}
                    smallCardData={dashboardData.treeSaved}
                  />
                </div>
              </div>
              <div className="deviceInfoWidgets">
                {/* <TotalDevices count={dashboardData.totalDeviceCount} /> */}
                {/* {dashboardData.totalDeviceCount} */}
                {/* <div className="deviceInfoSection-1"> */}
                <LargeCard
                  type={"Connected"}
                  largeCardData={dashboardData.connectedDeviceCount}
                  largeCardPercent={calculatePercentage(
                    dashboardData.connectedDeviceCount,
                    dashboardData.totalDeviceCount
                  )}
                  basis={dashboardData.totalDeviceCount}
                />

                {/* console.log(largeCardData) */}
                {/* data={dashboardData.notConnectedDeviceCount} */}
                <LargeCard
                  type={"ON"}
                  largeCardData={dashboardData.activeDeviceCount}
                  largeCardPercent={calculatePercentage(
                    dashboardData.activeDeviceCount,
                    dashboardData.connectedDeviceCount
                    // dashboardData.totalDeviceCount
                  )}
                  basis={dashboardData.connectedDeviceCount}
                />
                {/* </div> */}
                {/* <div className="deviceInfoSection-2"> */}
                {/* data={dashboardData.activeDeviceCount} */}
                <LargeCard
                  type={"OFF"}
                  largeCardData={dashboardData.inactiveDeviceCount}
                  largeCardPercent={calculatePercentage(
                    dashboardData.inactiveDeviceCount,
                    dashboardData.connectedDeviceCount
                  )}
                  basis={dashboardData.connectedDeviceCount}
                />
                {/* data={dashboardData.inactivedDeviceCount} */}
                <LargeCard
                  type={"FAULT"}
                  largeCardData={dashboardData.faultDeviceCount}
                  largeCardPercent={calculatePercentage(
                    dashboardData.faultDeviceCount,
                    dashboardData.connectedDeviceCount
                  )}
                  basis={dashboardData.connectedDeviceCount}
                />
                {/* data={dashboardData.faultDeviceCount} */}
                {/* </div> */}
              </div>
              <div className="plotWidgets">
                {/* <div className="lineChartContainer"> */}
                {/* <AreaChart/> */}

                {/* </div> */}
                {/* <div className="barChartContainer"> */}
                <BarChart
                  series={barChartSeries}
                  categories={barChartCategories}
                />
                <LineChart
                  series={lineChartSeries}
                  categories={lineChartCategories}
                />
                {/* <BarChart/> */}
                {/* </div> */}
                {/* <LineChart
                  series={lineChartSeries}
                  categories={lineChartCategories}
                />
                <BarChart
                  series={barChartSeries}
                  categories={barChartCategories}
                /> */}
              </div>
            </>
          )}
        </div>

        <Copyright />
      </div>
    </div>
  );
};

export default Dashboard;
