import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
// import "./DeviceChart.css";
import Cookies from "js-cookie";

const BarPlot = ({ series, categories }) => {
  const [chartOptions, setChartOptions] = useState({
    
  });

  return (
    <div>
      <Chart
        type="bar"
        series={series}
        width="100%"
        height="100%"
        options={{
          title: {
            text: "Session Length (Daily)",
            offsetY: 0,
            style: {
              fontSize: "15px",
              fontWeight: "bold",
              fontFamily: "Poppins, Arial",
              color: "#263238",
            },
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            style: {
              fontSize: "15px",
              fontFamily: "Poppins",
            },
          },
          chart: {
            stacked: false,
          },
          plotOptions: {
            bar: {
              vertical: true,
            },
          },
          stroke: {
            width: 1,
          },
          xaxis: {
            categories: categories,
            labels: {
              show: true,
              rotate: -45,
              style: {
                fontSize: "12.5px",
                fontFamily: "Poppins, Arial, sans-serif",
                fontWeight: 300,
              },
            },
          },
          yaxis: {
            title: {
              text: "Hours",
              maxWidth: 250,
              style: {
                fontSize: "15px",
                fontFamily: "Poppins, Arial, sans-serif",
                fontWeight: 400,
              },
            },
            labels: {
              show: true,
              align: "right",
              minWidth: 0,
              maxWidth: 150,
              style: {
                fontSize: "12.5px",
                fontFamily: "Poppins, Arial, sans-serif",
                fontWeight: 300,
              },
            },
          },
          legend: {
            show: true,
            position: "bottom",
            align: "center",
            fontSize: "15px",
            fontFamily: "Poppins, Arial",
            fontWeight: 400,
          },
          dataLabels: {
            enabled: false,
          },
          grid: {
            show: true,
            borderColor: "#90A4AE",
            strokeDashArray: 1,
            position: "back",
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val) {
              return val;
            },
            style: {
              fontSize: "10px",
              fontWeight: "bold",
            },
            // Adjust this value to position labels as desired
          },

          responsive: [
            {
              breakpoint: 1001, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "200%", // adjust the width for smaller screens
                  height: "95%",
                },
                title: {
                  style: {
                    fontSize: "14px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "10px",
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "10px",
                      // fontWeight: 400,
                    },
                  },
                },
                legend: {
                  fontSize: "10px",
                },
              },
            },
            {
              breakpoint: 1281, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "200%", // adjust the width for smaller screens
                  height: "150%",
                },
                title: {
                  style: {
                    fontSize: "13px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "8.5px",
                      fontWeight: 400,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "10px",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "11px",
                      // fontWeight: 400,
                    },
                  },
                },
                legend: {
                  fontSize: "11px",
                },
              },
            },
            {
              breakpoint: 1370, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "210%", // adjust the width for smaller screens
                  height: "115%",
                },
                title: {
                  style: {
                    fontSize: "13px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "8px",
                      fontWeight: 500,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "12.5px",
                      fontWeight: 500,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "12px",
                      fontWeight: 500,
                    },
                  },
                },
                legend: {
                  fontSize: "11px",
                },
              },
            },
            {
              breakpoint: 1501, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "140%", // adjust the width for smaller screens
                  height: "95%",
                },
                title: {
                  style: {
                    fontSize: "18px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "13px",
                      // fontWeight: 400,
                    },
                  },
                },
                legend: {
                  fontSize: "14px",
                },
              },
            },
            {
              breakpoint: 1701, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "300", // adjust the width for smaller screens
                  height: "150",
                },
                title: {
                  style: {
                    fontSize: "16px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "15px",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "15px",
                      // fontWeight: 400,
                    },
                  },
                },
                legend: {
                  fontSize: "15px",
                },
              },
            },
            {
              breakpoint: 1950, // define the breakpoint for responsiveness
              options: {
                chart: {
                  width: "280%", // adjust the width for smaller screens
                  height: "220%",
                },
                title: {
                  style: {
                    fontSize: "20px",
                  },
                },
                xaxis: {
                  labels: {
                    style: {
                      fontSize: "14px",
                      fontWeight: 400,
                    },
                  },
                },
                yaxis: {
                  title: {
                    style: {
                      fontSize: "16px",
                      fontWeight: 400,
                    },
                  },
                  labels: {
                    style: {
                      fontSize: "14px",
                      // fontWeight: 400,
                    },
                  },
                },
                legend: {
                  fontSize: "16px",
                },
              },
            },
          ],
        }}
      />
    </div>
  );
};

export default BarPlot;
