import React, { useEffect, useState } from "react";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "./MapView.css";
import Cookies from "js-cookie";
import { ThreeCircles, Triangle } from "react-loader-spinner";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router";
const TOKEN =
  "pk.eyJ1IjoiY3Jtcy10ZXN0IiwiYSI6ImNsazZqcnljaDAxdTQzZXFlcXZneHRwYWEifQ.GIfw5haMe7BIRV1KrgTq4A"; // Set your mapbox token here

const MapView = () => {
  const [activeMarkers, setActiveMarkers] = useState([]);
  const [inactiveMarkers, setInactiveMarkers] = useState([]);
  const [faultMarkers, setFaultMarkers] = useState([]);
  const [notConnectedMarkers, setNotConnectedMarkers] = useState([]);
  const [connectedMarkers, setConnectedMarkers] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [isLoadingMap, setIsLoadingMap] = useState(true);
  const navigate = useNavigate();
  // Fetch marker data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token"); // Get token from cookies
        if (!token) {
          navigate("/"); // Redirect to home if no token
          return;
        }

        const fetchMapViewData = async () => {
          const response = await fetch("/api/mapView", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            credentials: "include",
          });

          if (!response.ok) {
            if (response.status === 401) {
              // Unauthorized - Invalid token
              Cookies.remove("token");
              navigate("/");
            } else {
              // Handle other error cases
              throw new Error(`Request failed with status ${response.status}`);
            }
          }

          return response.json();
        };

        const poll = async () => {
          try {
            const mapViewData = await fetchMapViewData();
            console.log(mapViewData);
            setActiveMarkers(mapViewData.activeDeviceCoordinates);
            setInactiveMarkers(mapViewData.inactiveDeviceCoordinates);
            setFaultMarkers(mapViewData.faultDeviceCoordinates);
            setNotConnectedMarkers(mapViewData.notConnectedDeviceCoordinates);
            setConnectedMarkers(mapViewData.connectedDeviceCoordinates);
            setIsLoadingMap(false);
            // Poll again after a delay (e.g., every 5 seconds)
            setTimeout(poll, 60000);
          } catch (error) {
            console.error("Error fetching marker data:", error);
            setTimeout(poll, 60000);
          }
        };

        poll(); // Start the long polling process
      } catch (error) {
        console.error("Error during initial fetch:", error);
        setIsLoadingMap(false);
      }
    };

    fetchData();
  }, [navigate]);

  console.log(activeMarkers);
  console.log(inactiveMarkers);
  console.log(notConnectedMarkers);
  console.log(isLoadingMap);

  return (
    <div>
      {isLoadingMap ? (
        <>
          <div className="loader">
            <Triangle
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <span className="loadText">
              Please wait, I am loading your map...
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="mapViewLegend">
            <div className="legendSection">
              <div className="mapIcon">
                <LocationOnIcon style={{ fontSize: "1.5em", color: "green" }} />
              </div>
              <div className="mapHeading">Pump On</div>
            </div>
            <div className="legendSection">
              <div className="mapIcon">
                <LocationOnIcon
                  style={{ fontSize: "1.5em", color: "orange" }}
                />
              </div>
              <div className="mapHeading">Pump Off</div>
            </div>
            <div className="legendSection">
              <div className="mapIcon">
                <LocationOnIcon style={{ fontSize: "1.5em", color: "red" }} />
              </div>
              <div className="mapHeading">Fault</div>
            </div>
            <div className="legendSection">
              <div className="mapIcon">
                <LocationOnIcon style={{ fontSize: "1.5em", color: "grey" }} />
              </div>
              <div className="mapHeading">Not Connected</div>
            </div>
          </div>
          <div className="MapContainer">
            <Map
              initialViewState={{
                latitude: 23.850644124918915,
                longitude: 82.06671361553623,
                zoom: 5,
                bearing: 0,
                pitch: 0,
              }}
              mapStyle="mapbox://styles/mapbox/streets-v11"
              mapboxApiAccessToken={TOKEN}
            >
              {/* Render active markers */}
              {activeMarkers &&
                activeMarkers.map((marker, index) => (
                  <Marker
                    key={`active-${index}`}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    color="green"
                    scale="0.8"
                  ></Marker>
                ))}

              {/* Render inactive markers */}
              {inactiveMarkers &&
                inactiveMarkers.map((marker, index) => (
                  <Marker
                    key={`inactive-${index}`}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    color="orange"
                    scale="0.7"
                  ></Marker>
                ))}

              {/* Render fault markers */}
              {faultMarkers &&
                faultMarkers.map((marker, index) => (
                  <Marker
                    key={`fault-${index}`}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    color="red"
                    scale="0.6"
                  ></Marker>
                ))}

              {/* Render not connected markers */}
              {notConnectedMarkers &&
                notConnectedMarkers.map((marker, index) => (
                  <Marker
                    key={`not-connected-${index}`}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    scale="0.5"
                    color="grey"
                  ></Marker>
                ))}
              {/* Render not connected markers
              {connectedMarkers &&
                connectedMarkers.map((marker, index) => (
                  <Marker
                    key={`connected-${index}`}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                    scale="0.5"
                    color="blue"
                  ></Marker>
                ))} */}

              {/* Render popup for selected marker */}
              {selectedMarker && (
                <Popup
                  latitude={selectedMarker.latitude}
                  longitude={selectedMarker.longitude}
                  onClose={() => setSelectedMarker(null)}
                >
                  <div>
                    <h3>{selectedMarker.deviceName}</h3>
                    <p>{`{selectedMarker.address}`}</p>
                    <p>{`${selectedMarker.latitude}, ${selectedMarker.longitude}`}</p>
                  </div>
                </Popup>
              )}

              <GeolocateControl position="top-left" />
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              <ScaleControl />
            </Map>
          </div>
        </>
      )}
    </div>
  );
};

export default MapView;
