import React from "react";
import Copyright from "../../Global/Copyright";
import Sidebar from "../../Global/Sidebar";
import './Contact.css'
import Topbar from "../../Global/Topbar";
import emailIcon from "./email.png";
import addressIcon from "./building.png";
import phoneIcon from "./phone.png";
import locationMap from "./mapLocation.PNG";

const contact = () => {
  return (
    <div className="contactMainContainer">
      <div className="contactLeftContainer">
        <Sidebar />
      </div>
      <div className="contactRightContainer">
        <Topbar />
      <p className="contactUsHeading">Contact Us</p>
        <div className="addressMainContainer">
          <div className="addressLeftContainer">
            <p className="companyName">Greenovera India Pvt. Ltd </p>
            <div className="addressDetails, contactSection">
              <img className="contactIcons" src={addressIcon} alt="" />
              <div className="addressText">
                <p className="contactText">
                  18, Mott Lane (now Monilal Saha Lane)
                </p>
                <p className="contactText">
                  2nd Floor, Kolkata - 700 013
                </p>
              </div>
            </div>
            <div className="emailDetails, contactSection">
              <img className="contactIcons" src={emailIcon} alt="" />
              <p className="contactText">info@greenovera.com</p>
            </div>
            <div className="phoneNumber, contactSection">
              <img className="contactIcons" src={phoneIcon} alt="" />
              <p className="contactText">
                1800-313-8292{" "}
                <p className="contactTextBold">(Tollfree)</p>
              </p>
            </div>
          </div>
          <div className="addressRightContainer">
            <img className="mapLocation" src={locationMap} alt="" />
          </div>
        </div> 
      <Copyright className="contactFooter" />
      </div>
    </div>
  );
};

export default contact;
