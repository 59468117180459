import React from "react";
import { useNavigate } from "react-router";
import Copyright from "../../Global/Copyright";
import Topbar from "../../Global/Topbar";
import ListView from "../Devices/Views/ListView";
import "./MapView.css";
import MapView from "./Views/MapView2";
import Sidebar from "../../Global/Sidebar";
import Cookies from "js-cookie";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";

const Devices = () => {
  return (
    <div className="deviceMainContainer">
      <div className="deviceSidebarContainer">
        <Sidebar />
      </div>
      <div className="deviceSubContainer">
        <Topbar />
        <div className="deviceMainContent">
          <div className="deviceDataArea">
            <MapView />
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  );
};

export default Devices;
