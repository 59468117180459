import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import Sidebar from "../../Global/Sidebar";
import Topbar from "../../Global/Topbar";
import Copyright from "../../Global/Copyright";
import Card from "./DeviceComponents/Card";
import MiniCard from "./DeviceComponents/Card2";
import "./Device.css";
import { ThreeCircles, Triangle } from "react-loader-spinner";
import BarPlot from "./DeviceComponents/BarPlot";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import LinePlot from "./DeviceComponents/LinePlot";
const TOKEN =
  "pk.eyJ1IjoiY3Jtcy10ZXN0IiwiYSI6ImNsazZqcnljaDAxdTQzZXFlcXZneHRwYWEifQ.GIfw5haMe7BIRV1KrgTq4A"; // Set your mapbox token here

const Device = () => {
  const { devicename } = useParams();
  const [deviceData, setDeviceData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [lineData, setLineData] = useState(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [deviceStatus, setDeviceStatus] = useState(null);
  const [faultCode, setFaultCode] = useState(null);
  const [code, setCode] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token"); // Get token from cookies
        if (!token) {
          navigate("/"); // Redirect to home if no token
          return;
        }

        const fetchDeviceData = async () => {
          const response = await fetch(`/api/device/${devicename}`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            credentials: "include",
          });

          if (!response.ok) {
            if (response.status === 401) {
              // Unauthorized - Invalid token
              Cookies.remove("token");
              navigate("/");
            } else {
              // Handle other error cases
              throw new Error(`Request failed with status ${response.status}`);
            }
          }

          return response.json();
        };

        const poll = async () => {
          try {
            const deviceData = await fetchDeviceData();
            setDeviceData(deviceData);
            setBarData(deviceData.getdeviceHistoricalSessionTime);
            setLineData(deviceData.getdeviceHistoricalSaving);
            setIsLoadingTable(false);
            // Poll again after a delay (e.g., every 5 seconds)
            setTimeout(poll, 120000);
          } catch (error) {
            console.error("Error fetching device data:", error);
            setTimeout(poll, 120000);
          }
        };

        poll(); // Start the long polling process
      } catch (error) {
        console.error("Error during initial fetch:", error);
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, [devicename, navigate]);
  console.log(deviceData);
  console.log(barData);
  console.log(lineData);

  useEffect(() => {
    if (deviceData) {
      const deviceInfo = deviceData.getDeviceInfo[0];

      const last_sys_time = deviceInfo && deviceInfo.sys_time;
      const status = deviceInfo && deviceInfo.status;
      const faultname = deviceInfo && deviceInfo.faultname;

      // const lastSysTime = new Date(last_sys_time);
      const currentBrowserTimeIST = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      console.log(currentBrowserTimeIST);
      const currentTimeParts = currentBrowserTimeIST.split(":");
      const currentHours = currentTimeParts[0];
      const currentMinutes = currentTimeParts[1];
      const currentSeconds = currentTimeParts[2];

      const lastTimeParts = last_sys_time.split(":");
      const lastHours = lastTimeParts[0];
      const lastMinutes = lastTimeParts[1];
      const lastSeconds = lastTimeParts[2];

      const timeDifferenceMinutes =
        (parseInt(currentHours) - parseInt(lastHours)) * 60 +
        (parseInt(currentMinutes) - parseInt(lastMinutes));

      console.log(
        currentHours,
        currentMinutes,
        lastHours,
        lastMinutes,
        timeDifferenceMinutes
      );
      // Logic for setting deviceStatus
      if (status === "ON" && timeDifferenceMinutes < 60) {
        setDeviceStatus("Pump ON");
      } else if (status === "OFF" && timeDifferenceMinutes < 60) {
        setDeviceStatus("Pump OFF");
      } else if (status === "FAULT" && timeDifferenceMinutes < 60) {
        setDeviceStatus("Drive Fault");
        setFaultCode({ faultname });
        setCode("Code: ");
      } else {
        setDeviceStatus("Not Connected");
      }
    }
    console.log(deviceStatus);
  }, [deviceData]);
  const deviceInfo =
    deviceData && deviceData.getDeviceInfo && deviceData.getDeviceInfo[0];
  // Check if deviceInfo is truthy before accessing its properties
  const sysDate = deviceInfo && deviceInfo.sys_date;
  const sysTime = deviceInfo && deviceInfo.sys_time;
  const deviceName = deviceInfo && deviceInfo.devicename;
  const village = deviceInfo && deviceInfo.village;
  const block = deviceInfo && deviceInfo.block;
  const district = deviceInfo && deviceInfo.district;
  const state = deviceInfo && deviceInfo.state;
  const country = deviceInfo && deviceInfo.country;
  const pin = deviceInfo && deviceInfo.pin;
  const last_on_date = deviceInfo && deviceInfo.last_on_date;
  const last_on_sys_time = deviceInfo && deviceInfo.last_on_sys_time;
  const status = deviceInfo && deviceInfo.status;
  const agency = deviceInfo && deviceInfo.agency;
  const sim_number = deviceInfo && deviceInfo.sim_number;
  const variable_frequency_drive_name =
    deviceInfo && deviceInfo.variable_frequency_drive_name;
  const motor_hp = deviceInfo && deviceInfo.motor_hp;
  const voltage = deviceInfo && deviceInfo.voltage;
  const current = deviceInfo && deviceInfo.current;
  const installedcapacity = deviceInfo && deviceInfo.system_capacity_wp;
  const panels = deviceInfo && deviceInfo.solar_panels_no;
  const sessionTime = deviceInfo && deviceInfo.session_time;
  const fuelSavings = deviceInfo && deviceInfo.fuel_savings;
  const carbonSavings = deviceInfo && deviceInfo.carbon_savings;
  const lat = deviceInfo && deviceInfo.latitude;
  const long = deviceInfo && deviceInfo.longitude;
  // BAR PLOT DATA PROCESSING
  const session_time = barData
    ? barData.map((item) => parseInt(item.session_time))
    : [];
  const barPlot_date = barData ? barData.map((item) => parseInt(item.day)) : [];
  const barPlot_month = barData
    ? barData.map((item) => parseInt(item.month))
    : [];
  const barPlotCategories = barPlot_date.map(
    (day, index) => `${day}/${barPlot_month[index]}`
  );
  const BarPlotSeries = [
    {
      name: "Session Time",
      data: session_time,
    },
  ];
  // const barPlot_date = barData ? barData.map((item) => parseInt(item.day)) : [];
  console.log(session_time);
  console.log(barPlotCategories);
  // console.log(barPlot_month);

  // LINE PLOT DATA PROCESSING
  const fuel = lineData
    ? lineData.map((item) => parseInt(item.total_fuel_savings))
    : [];
  const carbon = lineData
    ? lineData.map((item) => parseInt(item.total_carbon_savings))
    : [];
  const linePlot_year = lineData
    ? lineData.map((item) => parseInt(item.year))
    : [];
  const linePlot_month = lineData
    ? lineData.map((item) => parseInt(item.month))
    : [];
  const linePlotCategories = linePlot_month.map(
    (month, index) => `${month}/${linePlot_year[index]}`
  );
  const linePlotSeries = [
    {
      name: "Fuel Saving",
      data: fuel,
    },
    {
      name: "Carbon Saving",
      data: carbon,
    },
  ];
  console.log(linePlotCategories);
  return (
    <div className="deviceMainContainer">
      <div className="deviceSidebarContainer">
        <Sidebar />
      </div>
      <div className="deviceSubContainer">
        <Topbar />
        <div className="deviceMainContent">
          {isLoadingTable ? (
            <>
              <div className="loader">
                <Triangle
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="triangle-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
                <span className="loadText3">
                  Please wait, I am loading your data...
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="deviceMainFrame">
                <div className="deviceTopFrame">
                  <div className="staticInfo">
                    <div className="updates">
                      <div className="deviceFontSmall">
                        Latest Update : {sysDate} , {sysTime}
                      </div>
                      <span className="simNo">Sim No. : {sim_number}</span>
                      <div className="deviceLastON">
                        Last ON Status : {last_on_date} , {last_on_sys_time}
                      </div>
                    </div>
                    <div className="devicename">
                      <div className="deviceNameLeft">
                        <div className="deviceFontXL">{deviceName}</div>
                        <div
                          className={`status ${getBackgroundColorClass(
                            deviceStatus
                          )}`}
                        >
                          {deviceStatus} {faultCode}
                        </div>
                      </div>
                      <div className="deviceNameRight">
                        <div className="deviceAddress">
                          Agency :{" "}
                          <span className="deviceDetails">{agency}</span>
                        </div>
                        <div className="deviceAddress">
                          Address:{" "}
                          <span className="deviceDetails">
                            {village}, {block}, {district}, {state}, {country} -{" "}
                            {pin}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bodyFrame">
                  <div className="bodyFrameLeft">
                    <div className="bframeLeftTop">
                      <div className="siteInfoCards">
                        <div className="siteInfoCardTop">
                          <Card
                            inputType={"vfd"}
                            cardData={variable_frequency_drive_name}
                          />
                          <Card inputType={"waterFlow"} cardData={motor_hp} />
                        </div>
                        <div className="siteInfoCardBottom">
                          {" "}
                          <Card
                            inputType={"installedCapacity"}
                            cardData={installedcapacity}
                          />
                          <Card inputType={"panels"} cardData={panels} />
                        </div>
                      </div>

                      <div className="barPlot">
                        <BarPlot
                          series={BarPlotSeries}
                          categories={barPlotCategories}
                        />
                      </div>
                    </div>
                    <div className="bframeLeftBottom">
                      <div className="location">
                        <Map
                          style={{ width: "100%", height: "100%" }}
                          initialViewState={{
                            latitude: deviceInfo && deviceInfo.latitude,
                            longitude: deviceInfo && deviceInfo.longitude,
                            zoom: 5,
                            bearing: 0,
                            pitch: 0,
                          }}
                          mapStyle="mapbox://styles/mapbox/streets-v11"
                          mapboxApiAccessToken={TOKEN}
                        >
                          {lat && long && (
                            <Marker
                              latitude={lat}
                              longitude={long}
                              color="green"
                              scale="0.8"
                            ></Marker>
                          )}
                          {/* <GeolocateControl position="top-left" /> */}
                          <FullscreenControl position="top-left" />
                          <NavigationControl position="top-left" />
                          {/* <ScaleControl /> */}
                        </Map>
                      </div>
                      <div className="linePlot">
                        <LinePlot
                          series={linePlotSeries}
                          categories={linePlotCategories}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="bodyFrameRight">
                    <div className="siteInfoCards2">
                      <MiniCard
                        inputType={"sessionTime"}
                        minicardData={sessionTime}
                      />

                      <MiniCard inputType={"voltage"} minicardData={voltage} />
                      <MiniCard inputType={"current"} minicardData={current} />
                      <MiniCard
                        inputType={"fuelSavings"}
                        minicardData={fuelSavings}
                      />
                      <MiniCard
                        inputType={"carbonSaving"}
                        minicardData={carbonSavings}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="devicefooter">
          <Copyright />
        </div>
      </div>
    </div>
  );
};

export default Device;

const getBackgroundColorClass = (status) => {
  switch (status) {
    case "Pump ON":
      return "pumpOnBackground";
    case "Pump OFF":
      return "pumpOffBackground";
    case "Drive Fault":
      return "driveFaultBackground";
    default:
      return "notConnectedBackground";
  }
};
