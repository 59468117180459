import React, { useState, useEffect } from "react";
import "./Topbar.css";
import { yellow } from "@mui/material/colors";
import NotificationAddIcon from "@mui/icons-material/NotificationAdd";
import Data from "../../ClientSideData.json";
import Cookies from "js-cookie";
const Topbar = () => {
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString()
  );
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  setInterval(() => {
    setCurrentTime(new Date().toLocaleTimeString());
  }, 1000);

  const [userName, setUserName] = useState([]);
  const [lastUpdated, setLastUpdated] = useState(
    new Date().toLocaleTimeString()
  );
  useEffect(() => {
    fetch("/api/topBar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Cookies.get("token")}`,
      },
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUserName(data.userName);
        // setLastUpdated(data.lastUpdated);
      })
      .catch((error) => {
        console.error("Error fetching marker data:", error);
      });
  }, []);

  return (
    <div className="top">
      <div className="topLeft">
        {/* DATE AND TIME INFO WITH LAST UPDATE*/}
        <p>
          Date And Time : {currentDate} {currentTime}
        </p>
        <p className="lastUpdate">
          Last Updates: {currentDate} {lastUpdated}
        </p>
      </div>
      <div className="topRight">
        <div className="ClientName">
          <span>Welcome</span>
          {userName}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
