import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import "./listView.css";
import LinearProgress from "@mui/material/LinearProgress";
import { ThreeCircles, Triangle } from "react-loader-spinner";
import LinkIcon from "@mui/icons-material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
const columns = [
  {
    field: "",
    headerName: "View Details",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Link to={`/device/${params.row.devicename}`}>
        <IconButton>
          <LaunchIcon />
        </IconButton>
      </Link>
    ),
  },
  {
    field: "devicename",
    headerName: "Device Name",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "status",
    headerName: "Status",
    width: 70,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sys_date",
    headerName: "Last Updated",
    width: 180,
    headerAlign: "center",
    align: "center",
    valueGetter: (params) => {
      // Concatenate "sys_date" and "sys_time" values
      const sysDate = params.row.sys_date;
      const sysTime = params.row.sys_time;
      return sysDate ? `${sysDate} ${sysTime}` : "";
    },
  },
  // {
  //   field: "sys_time",
  //   headerName: "Last Updated (Time)",
  //   width: 150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "voltage",
    headerName: "Voltage",
    width: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "current",
    headerName: "Current",
    width: 80,
    headerAlign: "center",
    align: "center",
    // valueGetter: (params) =>
    //   params.row.current ? params.row.current / 10 : null,
  },
  {
    field: "frequency",
    headerName: "Frequency",
    width: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "flow_rate",
    headerName: "Flow rate LPS",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "session_time",
    headerName: "Session Time",
    width: 120,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "water_output",
    headerName: "Total Water output(L)",
    width: 160,
    headerAlign: "center",
    align: "center",
  },
  // {
  //   field: "motor_hp",
  //   headerName: "Pump Capacity (hp)",
  //   width: 120,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "system_capacity_wp",
  //   headerName: "Installed Power (Wp)",
  //   width: 120,
  //   headerAlign: "center",
  //   align: "center",
  // },

  // {
  //   field: "last_on_date",
  //   headerName: "Last ON Date",
  //   width: 150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  // {
  //   field: "last_on_sys_time",
  //   headerName: "Last ON Time",
  //   width: 150,
  //   headerAlign: "center",
  //   align: "center",
  // },
  {
    field: "agency",
    headerName: "Funding Agency",
    width: 300,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "village",
    headerName: "Village",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "block",
    headerName: "Block",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "district",
    headerName: "District",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "state",
    headerName: "State",
    width: 150,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "pin",
    headerName: "Pin Code",
    width: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "country",
    headerName: "Country",
    width: 80,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "latitude",
    headerName: "Latitude",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "longitude",
    headerName: "Longitude",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "sim_number",
    headerName: "SIM Number",
    width: 200,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "variable_frequency_drive_name",
    headerName: "Var. Freq. Drive",
    width: 130,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "faultname",
    headerName: "Fault Name",
    width: 100,
    headerAlign: "center",
    align: "center",
  },
];

export default function DataTable() {
  const [rows, setRows] = useState([]);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get("token"); // Get token from cookies
        if (!token) {
          navigate("/"); // Redirect to home if no token
          return;
        }

        const fetchDeviceList = async () => {
          const response = await fetch("/api/devicelist", {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${token}`,
            },
            credentials: "include",
          });

          if (!response.ok) {
            if (response.status === 401) {
              // Unauthorized - Invalid token
              Cookies.remove("token");
              navigate("/");
            } else {
              // Handle other error cases
              throw new Error(`Request failed with status ${response.status}`);
            }
          }

          return response.json();
        };

        const poll = async () => {
          try {
            const deviceListData = await fetchDeviceList();
            setRows(deviceListData);
            setIsLoadingTable(false);
            // Poll again after a delay (e.g., every 5 seconds)
            setTimeout(poll, 120000);
          } catch (error) {
            console.error("Error fetching device list:", error);
            setTimeout(poll, 120000);
          }
        };

        poll(); // Start the long polling process
      } catch (error) {
        console.error("Error during initial fetch:", error);
        setIsLoadingTable(false);
      }
    };

    fetchData();
  }, [navigate]);

  return (
    <div className="listViewContainer">
      {isLoadingTable ? (
        <>
          <div className="loader">
            <Triangle
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
            <span className="loadText">
              Please wait, I am loading your table...
            </span>
          </div>
        </>
      ) : (
        <>
          <DataGrid
            sx={{
              align: "center",
              textAlign: "center",
              // width: "100",
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            rows={rows}
            columns={columns}
            //{columns.concat(actionColumn)}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25 },
              },
              sorting: {
                sortModel: [{ field: "sys_date", sort: "desc" }],
              },
            }}

            // pageSizeOptions={[20, 50]}
            // checkboxSelection
          />
        </>
      )}
    </div>
  );
}
