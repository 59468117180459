import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import "./Chart.css";
import Cookies from "js-cookie";

const BarChart = ({ series, categories }) => {
  const [chartOptions, setChartOptions] = useState({
    title: {
      text: "Historical Device Status (Daily)",
      offsetY: 10,
      style: {
        fontSize: "22px",
        fontWeight: "bold",
        fontFamily: "Poppins, Arial",
        color: "#263238",
      },
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      style: {
        fontSize: "15px",
        fontFamily: "Poppins",
      },
    },
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
    },
    xaxis: {
      categories: categories,
      labels: {
        show: true,
        style: {
          fontSize: "12.5px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 300,
        },
      },
    },
    yaxis: {
      title: {
        text: "No. of Devices",
        maxWidth: 250,
        style: {
          fontSize: "15px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 600,
        },
      },
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 150,
        style: {
          fontSize: "12.5px",
          fontFamily: "Poppins, Arial, sans-serif",
          fontWeight: 300,
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      align: "center",
      fontSize: "15px",
      fontFamily: "Poppins, Arial",
      fontWeight: 400,
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 1,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      style: {
        fontSize: "10px",
        fontWeight: "bold",
      },
      // Adjust this value to position labels as desired
    },

    responsive: [
      {
        breakpoint: 1001, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "105%", // adjust the width for smaller screens
            height: "95%",
          },
          title: {
            style: {
              fontSize: "14px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "10px",
              },
            },
            labels: {
              style: {
                fontSize: "10px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "10px",
          },
        },
      },
      {
        breakpoint: 1281, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "120%", // adjust the width for smaller screens
            height: "95%",
          },
          title: {
            style: {
              fontSize: "14px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "10px",
                fontWeight: 600,
              },
            },
            labels: {
              style: {
                fontSize: "11px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "11px",
          },
        },
      },
      {
        breakpoint: 1370, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "125%", // adjust the width for smaller screens
            height: "100%",
          },
          title: {
            style: {
              fontSize: "14px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "10px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "10.5px",
                fontWeight: 600,
              },
            },
            labels: {
              style: {
                fontSize: "10px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "11px",
          },
        },
      },
      {
        breakpoint: 1501, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "140%", // adjust the width for smaller screens
            height: "95%",
          },
          title: {
            style: {
              fontSize: "18px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
              },
            },
            labels: {
              style: {
                fontSize: "13px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "14px",
          },
        },
      },
      {
        breakpoint: 1701, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "145%", // adjust the width for smaller screens
            height: "95%",
          },
          title: {
            style: {
              fontSize: "16px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "15px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "15px",
                fontWeight: 600,
              },
            },
            labels: {
              style: {
                fontSize: "15px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "15px",
          },
        },
      },
      {
        breakpoint: 1950, // define the breakpoint for responsiveness
        options: {
          chart: {
            width: "155%", // adjust the width for smaller screens
            height: "95%",
          },
          title: {
            style: {
              fontSize: "20px",
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "18px",
                fontWeight: 600,
              },
            },
          },
          yaxis: {
            title: {
              style: {
                fontSize: "16px",
                fontWeight: 600,
              },
            },
            labels: {
              style: {
                fontSize: "14px",
                // fontWeight: 600,
              },
            },
          },
          legend: {
            fontSize: "16px",
          },
        },
      },
    ],
  });

  return (
    <div className="barChart">
      <Chart
        type="bar"
        series={series}
        width="200%"
        height="95%"
        options={chartOptions}
      />
    </div>
  );
};

export default BarChart;
