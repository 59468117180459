import React from "react";
import "./largeCard.css";
import "../../Pages/Dashboard/Dashboard.css";
// import "react-circular-progressbar/dist/styles.css";
import activeIcon from "../../Pages/Dashboard/Icons/dev_active.png";
import waterPump from "../../Pages/Dashboard/Icons/waterPump.png";
import RadialBar from "./radialBar";
import inactiveIcon from "../../Pages/Dashboard/Icons/dev_inactive.png";
import faultIcon from "../../Pages/Dashboard/Icons/dev_fault.png";
import connectedIcon from "../../Pages/Dashboard/Icons/dev_connected.png";
import liveIcon from "../../Pages/Dashboard/Icons/live.png";
const largeCard = ({ type, largeCardData, largeCardPercent, basis }) => {
  let data;
  switch (type) {
    case "Connected":
      data = {
        title: "Connectivity",
        icon: connectedIcon,
        value: largeCardData || 0,
        percent: largeCardPercent,
        color: "#03039e",
      };
      break;
    case "ON":
      data = {
        title: "Pump ON",
        icon: waterPump,
        value: largeCardData || 0,
        percent: largeCardPercent,
        color: "#ABE5A1",
      };
      break;
    case "OFF":
      data = {
        title: "Pump OFF",
        icon: inactiveIcon,
        value: largeCardData || 0,
        percent: largeCardPercent,
        color: "#ffea00",
      };
      break;
    case "FAULT":
      data = {
        title: "Faults",
        icon: faultIcon,
        value: largeCardData || 0,
        percent: largeCardPercent,
        color: "#ff0800",
      };
      break;
    default:
      data = {
        title: "Unknown",
        icon: null,
        value: 0,
        percent: "",
      };
      break;
  }

  return (
    <div className="largeCardContainer">
      <div className="largeCardLeftSide">
        <div className="leftTop">
          <img className="largeCardIcon" src={data.icon} alt="icon" />
          <img className="liveICON" src={liveIcon} alt="icon" />
        </div>
        <span className="largeCardTitle">{data.title}</span>
        <span className="largeCardNumber">
          <span Classname="largeCardValue">{data.value}</span>
          <span className="largeCardSlash"> | </span>
          <span className="largeCardBasis">{basis}</span>
        </span>
      </div>
      <div className="largeCardRightSide">
        <div className="progressBar">
          <RadialBar val={data.percent} col={data.color} />
        </div>
      </div>
    </div>
  );
};

export default largeCard;
