import React from "react";
import "./smallCard.css";
import solarIcon from "../../Pages/Dashboard/Icons/solar_panels.png";
import clockIcon from "../../Pages/Dashboard/Icons/clock.png";
import waterIcon from "../../Pages/Dashboard/Icons/water_drop.png";
import fuelIcon from "../../Pages/Dashboard/Icons/fuels.png";
import plantIcon from "../../Pages/Dashboard/Icons/plant.png";
import installIcon from "../../Pages/Dashboard/Icons/dev_installed.png";
const smallCard = ({ inputType, smallCardData }) => {
  let CardData;
  switch (inputType) {
    case "totalDevice":
      CardData = {
        cardTitle: "Installed Devices",
        cardIcon: installIcon,
        cardValue: smallCardData || 0,
        cardUnit: "Nos.",
      };
      break;
    case "installedCapacity":
      CardData = {
        cardTitle: "Installed Capacity",
        cardIcon: solarIcon,
        cardValue: smallCardData || 0,
        cardUnit: "kW",
      };
      break;
    case "sessionTime":
      CardData = {
        cardTitle: "Session Time",
        cardIcon: clockIcon,
        cardValue: smallCardData || 0,
        cardUnit: "Hrs.",
      };
      break;
    case "waterFlow":
      CardData = {
        cardTitle: "Pumping Capacity",
        cardIcon: waterIcon,
        cardValue: smallCardData || 0,
        cardUnit: "HP",
      };
      break;
    case "fuelSavings":
      CardData = {
        cardTitle: "Fuel Saving",
        cardIcon: fuelIcon,
        cardValue: smallCardData || 0,
        cardUnit: "litres",
      };
      break;
    case "carbonSaving":
      CardData = {
        cardTitle: "Carbon Saving",
        cardIcon: plantIcon,
        cardValue: smallCardData || 0,
        cardUnit: "Trees",
      };
      break;
    default:
      CardData = {
        cardTitle: "Unknown",
        cardIcon: null,
        cardValue: 0,
        cardUnit: "",
      };
      break;
  }
  return (
    <div className="smallCardContainer">
      <div className="smallCardLeftSide">
        <img className="smallCardIcon" src={CardData.cardIcon} alt="icon" />
        <span className="smallCardTitle">{CardData.cardTitle}</span>
      </div>
      <div className="smallCardRightSide">
        <span className="smallCardNumber">{CardData.cardValue}</span>
        <span className="smallCardUnit">{CardData.cardUnit}</span>
      </div>
    </div>
  );
};

export default smallCard;
